import { template as template_0c8e1a55861148fd8b6b42413028dfca } from "@ember/template-compiler";
const FKText = template_0c8e1a55861148fd8b6b42413028dfca(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
