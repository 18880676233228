import { template as template_1bb53331357c41fca689f301e9df0767 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1bb53331357c41fca689f301e9df0767(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
