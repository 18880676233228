import { template as template_40929450fff84cc6957ae360e1269a2d } from "@ember/template-compiler";
const FKControlMenuContainer = template_40929450fff84cc6957ae360e1269a2d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
